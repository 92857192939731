import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getDetailUser } from "services/profile";

export const AuthContext = React.createContext();

const fakeUserData = {
  id: 1,
  name: "Jhon Doe",
  avatar:
    "http://s3.amazonaws.com/redqteam.com/isomorphic-reloaded-image/profilepic.png",
  roles: ["USER", "ADMIN"],
};

const isLoggedIn = () => {
  return localStorage.getItem("token") != null;
};

const AuthProvider = (props) => {
  let history = useHistory();
  const [loggedIn, setLoggedIn] = useState(isLoggedIn());
  const [user, setUser] = useState();

  useEffect(() => {
    if (loggedIn) {
      getDetailUser().then((data) => setUser(data?.result));
    }
  }, [loggedIn]);

  const signIn = () => {
    setLoggedIn(true);
    history.push(`/profile`);
  };

  const signUp = () => {
    setLoggedIn(true);
    history.push(`/`);
  };

  const logOut = () => {
    setUser(null);
    setLoggedIn(false);
  };

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        logOut,
        signIn,
        signUp,
        user,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
