import { base } from "../baseAuth";

export const postLoginUser = async (data) => {
  try {
    const response = await base.post("/auth/login", data);
    return Promise.resolve(response.data);
  } catch (error) {
    console.log(error, "test");
    return Promise.reject(error);
  }
};
