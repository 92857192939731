import React, { useContext } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import { AuthContext } from "./context/AuthProvider";
import Layout from "./container/Layout/Layout";
import {
  LOGIN_PAGE,
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  // SINGLE_POST_PAGE,
  ADD_HOTEL_PAGE,
  ABOUT_COMPANY_PAGE,
  AGENT_ACCOUNT_SETTINGS_PAGE,
  CONTACT_COMPANY,
  GALERY_PAGE,
  GALERY_DETAIL,
  SIMULASI_KPR,
  APARTEMEN_PAGES,
  ARTICLE_DETAIL,
  ARTICLE_LIST,
  ARTICLE_DETAIL2,
  ARTICLE_DETAIL3,
  ARTICLE_DETAIL4,
  ARTICLE_DETAIL5,
  PRIVACY_PAGE,
  TERMS_PAGE,
  PROFILE_PAGE,
  ARTICLE_DETAIL6,
} from "./settings/constant";

/**
 *
 * Public Routes
 *
 */
const Loading = () => null;

const routes = [
  {
    path: HOME_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Home" */ "./container/Home/Home"),
      loading: Loading,
      modules: ["Home"],
    }),
    exact: true,
  },
  // {
  //   path: `${SINGLE_POST_PAGE}/:slug`,
  //   component: Loadable({
  //     loader: () =>
  //       import(
  //         /* webpackChunkName: "SinglePageView" */ "./container/SinglePage/SinglePageView"
  //       ),
  //     loading: Loading,
  //     modules: ["SinglePageView"],
  //   }),
  // },
  {
    path: LISTING_POSTS_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Listing" */ "./container/Listing/Listing"),
      loading: Loading,
      modules: ["Listing"],
    }),
  },
  {
    path: ABOUT_COMPANY_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "AgentDetailsViewPage" */ "./container/Agent/AccountDetails/AgentDetailsViewPage"
        ),
      loading: Loading,
      modules: ["AgentDetailsViewPage"],
    }),
  },
  {
    path: ARTICLE_DETAIL,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "AgentDetailsViewPage" */ "./container/ArticleDetail/ArticleDetail"
        ),
      loading: Loading,
      modules: ["ArticleDetail"],
    }),
  },
  {
    path: ARTICLE_DETAIL2,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "AgentDetailsViewPage" */ "./container/ArticleDetail/ArticleDetail2"
        ),
      loading: Loading,
      modules: ["ArticleDetail"],
    }),
  },
  {
    path: ARTICLE_DETAIL3,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "AgentDetailsViewPage" */ "./container/ArticleDetail/ArticleDetail3"
        ),
      loading: Loading,
      modules: ["ArticleDetail"],
    }),
  },
  {
    path: ARTICLE_DETAIL4,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "AgentDetailsViewPage" */ "./container/ArticleDetail/ArticleDetail4"
        ),
      loading: Loading,
      modules: ["ArticleDetail"],
    }),
  },
  {
    path: ARTICLE_DETAIL5,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "AgentDetailsViewPage" */ "./container/ArticleDetail/ArticleDetail5"
        ),
      loading: Loading,
      modules: ["ArticleDetail"],
    }),
  },
  {
    path: ARTICLE_DETAIL6,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "AgentDetailsViewPage" */ "./container/ArticleDetail/ArticleDetail6"
        ),
      loading: Loading,
      modules: ["ArticleDetail"],
    }),
  },
  {
    path: ARTICLE_LIST,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "AgentDetailsViewPage" */ "./container/Article/Article"
        ),
      loading: Loading,
      modules: ["Article"],
    }),
  },
  {
    path: CONTACT_COMPANY,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Pricing" */ "./container/Pricing/Pricing"),
      loading: Loading,
      modules: ["Pricing"],
    }),
  },
  {
    path: GALERY_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Pricing" */ "./container/Galery/Galery"),
      loading: Loading,
      modules: ["Pricing"],
    }),
  },
  {
    path: GALERY_DETAIL,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "Pricing" */ "./container/Galery/GaleryDetail"
        ),
      loading: Loading,
      modules: ["Pricing"],
    }),
  },
  {
    path: SIMULASI_KPR,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "Pricing" */ "./container/Simulatation/Simulation"
        ),
      loading: Loading,
      modules: ["Simulation"],
    }),
  },
  {
    path: APARTEMEN_PAGES,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "Pricing" */ "./container/Apartemen/Apartemen"
        ),
      loading: Loading,
      modules: ["Apartemen"],
    }),
  },
  {
    path: PRIVACY_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Pricing" */ "./container/Privacy/Privacy"),
      loading: Loading,
      modules: ["Privacy"],
    }),
  },
  {
    path: TERMS_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Pricing" */ "./container/Terms/Terms"),
      loading: Loading,
      modules: ["Terms"],
    }),
  },
  // {
  //   path: PROFILE_PAGE,
  //   component: Loadable({
  //     loader: () =>
  //       import(/* webpackChunkName: "Profile" */ "./container/Profile/Profile"),
  //     loading: Loading,
  //     modules: ["Profile"],
  //   }),
  // },
];

/**
 *
 * Protected Route Component
 *
 */
const AddListing = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddListing" */ "./container/AddListing/AddListing"
    ),
  loading: Loading,
  modules: ["AddListing"],
});

const ProfilePage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Profile" */ "./container/Profile/Profile"),
  loading: Loading,
  modules: ["ProfilePage"],
});

const AgentAccountSettingsPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AgentAccountSettingsPage" */ "./container/Agent/AccountSettings/AgentAccountSettingsPage"
    ),
  loading: Loading,
  modules: ["AgentAccountSettingsPage"],
});

/**
 *
 * Not Found Route Component
 *
 */

const NotFound = Loadable({
  loader: () =>
    import(/* webpackChunkName: "NotFound" */ "./container/404/404"),
  loading: Loading,
  modules: ["NotFound"],
});

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { loggedIn } = useContext(AuthContext);
  return (
    <Route
      render={(props) =>
        loggedIn ? <Component {...props} /> : <Redirect to={HOME_PAGE} />
      }
      {...rest}
    />
  );
};

/**
 *
 * Overall Router Component
 *
 */

const Routes = () => {
  return (
    <Layout>
      <Switch>
        {routes.map(({ path, component, exact = false }) => (
          <Route key={path} path={path} exact={exact} component={component} />
        ))}
        <ProtectedRoute path={ADD_HOTEL_PAGE} component={AddListing} />
        <ProtectedRoute
          path={AGENT_ACCOUNT_SETTINGS_PAGE}
          component={AgentAccountSettingsPage}
        />
        <ProtectedRoute path={PROFILE_PAGE} component={ProfilePage} />
        <Route component={NotFound} />
      </Switch>
    </Layout>
  );
};

export default Routes;
