import React, { Fragment, useState, useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Menu } from "antd";

import jwt_decode from "jwt-decode";

import { Button, Modal, Form, Image } from "react-bootstrap";
import Text from "antd/lib/typography/Text";
import PinInput from "react-pin-input";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { AuthContext } from "context/AuthProvider";
import { postLoginUser } from "services/login";

const AuthMenu = ({ className }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalForgot, setModalForgot] = React.useState(false);
  const [modalShowOtp, setModalShowOtp] = React.useState(false);
  const [modalRegister, setmodalRegister] = useState(false);

  // Style
  const ButtonLoginMenu = styled.div`
    /* Adapt the colors based on primary prop */
    background: ${(props) => (props.primary ? "#1AA5E1" : "white")};
    color: ${(props) => (props.primary ? "white" : "#1AA5E1")};
    font-weight: bold;
    font-size: 1em;
    margin: 1em;
    padding: 0.5em 2em;
    border: 1px solid ${(props) => (props.primary ? "#1AA5E1" : "#1AA5E1")};
    border-radius: 12px;
    font-family: Judson;
  `;

  const { signIn, loggedIn } = useContext(AuthContext);

  const handleForgot = () => {
    setModalShow(false);
    setModalForgot(true);
  };

  const btnLoginClick = () => {
    setModalShow(true);
    setModalForgot(false);
  };

  const persistAuth = () => {
    const token = localStorage.getItem("token");
    if (token) {
      signIn();
    }
  };

  useEffect(() => {
    persistAuth();
  }, []);

  const handleLogin = async (data) => {
    try {
      data.type = "customer";

      const response = await postLoginUser(data);
      const token = response.result.access_token;
      if (response.flag == 200) {
        localStorage.setItem("token", token);
        signIn();
        // console.log(jwt_decode(token));
      }
    } catch (error) {
      console.log(error);
      alert("error");
    }
  };

  // if (loggedIn) {
  //   return <Redirect push to="/profile" />;
  // }

  return (
    <Fragment>
      <Menu className={className}>
        <Menu.Item key="0">
          <ButtonLoginMenu primary onClick={() => btnLoginClick()}>
            Login
          </ButtonLoginMenu>
        </Menu.Item>
      </Menu>
      <LoginComponent
        onClick={() => {
          setModalShow(false);
        }}
        show={modalShow}
        onHide={() => setModalShow(false)}
        handleLogin={handleLogin}
        handleForgot={handleForgot}
      />
      <ForgotComponent
        onClick={() => {
          setModalForgot(false);
        }}
        show={modalForgot}
        onHide={() => setModalForgot(false)}
      />
      {/* <RegisterComponent
        onClick={() => {
          setmodalRegister(false);
          setModalShowOtp(true);
        }}
        show={modalRegister}
        onHide={() => setmodalRegister(false)}
      /> */}
      {/* <PinComponent
        onClick={() => {
          setModalShowOtp(false);
          handleSocialAuth("google");
        }}
        show={modalShowOtp}
        onHide={() => setModalShowOtp(false)}
      /> */}
    </Fragment>
  );
};

function LoginComponent({ show, onHide, onClick, handleLogin, handleForgot }) {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    handleLogin(data);
    onClick();
  };
  return (
    <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body style={{ paddingRight: 50, paddingLeft: 50 }}>
        <div
          onClick={onHide}
          style={{
            position: "absolute",
            left: "92%",
            right: 60,
            top: 10,
            bottom: 0,
          }}
        >
          <h3
            style={{ color: "#CBCBCB", fontWeight: "bold", cursor: "pointer" }}
          >
            {" "}
            x{" "}
          </h3>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <center>
                <Form.Label style={{ fontWeight: "bold" }}>Masuk</Form.Label>
              </center>
            </div>

            <Form.Control
              name="email"
              placeholder="Masukan Email"
              style={{ borderWidth: 1, borderColor: "#CBCBCB", height: 56 }}
              ref={register({
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i,
                  message: "Enter valid email.",
                },
              })}
            />
            {errors.email && errors.email.message}
            <br />
            <Form.Control
              name="password"
              type="password"
              placeholder="Masukan password"
              style={{ borderWidth: 1, borderColor: "#CBCBCB", height: 56 }}
              ref={register({
                required: "Password is required",
              })}
            />
          </Form.Group>
          <div className="d-grid gap-2">
            <Button
              variant="primary"
              size="lg"
              style={{ backgroundColor: "#1AA5E1", height: 56 }}
              onClick={handleSubmit(onSubmit)}
            >
              Masuk
            </Button>
          </div>
          <p
            onClick={() => handleForgot()}
            style={{
              fontSize: 16,
              color: "#232323",
              marginLeft: "35%",
              marginTop: 20,
              cursor: "pointer",
            }}
          >
            Lupa Password ?
          </p>
        </Form>
        <div style={{ marginBottom: 25 }} />
      </Modal.Body>
    </Modal>
  );
}

function ForgotComponent({ show, onHide, onClick }) {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    onClick(console.log(data));
  };

  return (
    <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body style={{ paddingRight: 50, paddingLeft: 50 }}>
        <div
          onClick={onHide}
          style={{
            position: "absolute",
            left: "92%",
            right: 60,
            top: 10,
            bottom: 0,
          }}
        >
          <h3
            style={{ color: "#CBCBCB", fontWeight: "bold", cursor: "pointer" }}
          >
            {" "}
            x{" "}
          </h3>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <center>
                <Form.Label style={{ fontWeight: "bold" }}>
                  Forgot Password
                </Form.Label>
              </center>
              <center>
                <Image src={"/images/forgot-pass.png"} />
              </center>
            </div>

            <Form.Control
              name="email"
              placeholder="Masukan Email"
              style={{ borderWidth: 1, borderColor: "#CBCBCB", height: 56 }}
              ref={register({
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i,
                  message: "Enter valid email.",
                },
              })}
            />
            {errors.email && errors.email.message}
          </Form.Group>
          <div className="d-grid gap-2">
            <Button
              variant="primary"
              size="lg "
              style={{ backgroundColor: "#1AA5E1", height: 56 }}
              onClick={handleSubmit(onSubmit)}
            >
              Kirim
            </Button>
          </div>
        </Form>
        <div style={{ marginBottom: 25 }} />
      </Modal.Body>
    </Modal>
  );
}

// function PinComponent({ show, onHide, onClick }) {
//   return (
//     <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered>
//       <Modal.Body style={{ paddingRight: 50, paddingLeft: 50 }}>
//         <div
//           onClick={onHide}
//           style={{
//             position: "absolute",
//             left: "92%",
//             right: 60,
//             top: 10,
//             bottom: 0,
//           }}
//         >
//           <h3 style={{ color: "#CBCBCB", fontWeight: "bold" }}> x </h3>
//         </div>
//         <Form>
//           <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
//             <div style={{ marginTop: 20, marginBottom: 20 }}>
//               <center>
//                 <Form.Label style={{ fontWeight: "bold" }}>
//                   Masukkan Kode OTP
//                 </Form.Label>
//               </center>
//             </div>

//             <PinInput
//               length={5}
//               focus
//               // disabled
//               // ref={(p) => (this.pin = p)}
//               inputStyle={{
//                 borderRadius: 12,
//                 backgroundColor: "#F8F8F8",
//                 borderWidth: 1,
//                 borderColor: "#CBCBCB",
//                 marginLeft: 25,
//               }}
//               style={{
//                 alignSelf: "center",
//                 width: "100%",
//               }}
//               type="numeric"
//               // onChange={this.onChange}
//             />
//           </Form.Group>
//           <div className="d-grid gap-2">
//             <Button
//               onClick={onClick}
//               variant="primary"
//               size="lg"
//               style={{ backgroundColor: "#1AA5E1", height: 56 }}
//             >
//               Masuk
//             </Button>
//           </div>
//         </Form>
//       </Modal.Body>
//     </Modal>
//   );
// }

// function RegisterComponent({ show, onHide, onClick }) {
//   return (
//     <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered>
//       <Modal.Body style={{ paddingRight: 50, paddingLeft: 50 }}>
//         <div
//           onClick={onHide}
//           style={{
//             position: "absolute",
//             left: "92%",
//             right: 60,
//             top: 10,
//             bottom: 0,
//           }}
//         >
//           <h3 style={{ color: "#CBCBCB", fontWeight: "bold" }}> x </h3>
//         </div>
//         <Form>
//           <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
//             <div style={{ marginTop: 20, marginBottom: 20 }}>
//               <center>
//                 <Form.Label style={{ fontWeight: "bold" }}>Daftar</Form.Label>
//               </center>
//             </div>

//             <Form.Control
//               placeholder="Masukan email"
//               style={{ borderWidth: 1, borderColor: "#CBCBCB", height: 56 }}
//             />
//             <br />
//             <Form.Control
//               placeholder="Masukan nomor hp"
//               style={{ borderWidth: 1, borderColor: "#CBCBCB", height: 56 }}
//             />
//             <br />
//             <Form.Control
//               type="password"
//               placeholder="Masukan password"
//               style={{ borderWidth: 1, borderColor: "#CBCBCB", height: 56 }}
//             />
//           </Form.Group>
//           <div className="d-grid gap-2">
//             <Button
//               variant="primary"
//               size="lg"
//               style={{ backgroundColor: "#1AA5E1", height: 56 }}
//               onClick={onClick}
//             >
//               Daftar
//             </Button>
//           </div>
//         </Form>
//         <div style={{ marginTop: 40, marginBottom: 40 }}>
//           <center>
//             <Text>
//               Sudah punya akun <Text style={{ color: "#1AA5E1" }}>Login</Text>
//             </Text>
//           </center>
//         </div>
//       </Modal.Body>
//     </Modal>
//   );
// }

export default AuthMenu;
