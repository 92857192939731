import React from "react";
import { NavLink } from "react-router-dom";
import { Menu } from "antd";

import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  ABOUT_COMPANY_PAGE,
  CONTACT_COMPANY,
  GALERY_PAGE,
  SIMULASI_KPR,
  ARTICLE_LIST,
} from "settings/constant";

const MainMenu = ({ className }) => {
  return (
    <Menu className={className}>
      <Menu.Item key="0">
        <NavLink exact to={`${HOME_PAGE}`} style={{ fontFamily: "Kadwa" }}>
          Home
        </NavLink>
      </Menu.Item>
      <Menu.Item key="1">
        <NavLink to={`${ABOUT_COMPANY_PAGE}`} style={{ fontFamily: "Kadwa" }}>
          Tentang
        </NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink to={`${LISTING_POSTS_PAGE}`} style={{ fontFamily: "Kadwa" }}>
          Produk
        </NavLink>
      </Menu.Item>
      <Menu.Item key="4">
        <NavLink to={`${SIMULASI_KPR}`} style={{ fontFamily: "Kadwa" }}>
          Simulasi
        </NavLink>
      </Menu.Item>
      {/* <Menu.Item key="5">
        <NavLink to={`${GALERY_PAGE}`} style={{ fontFamily: "Kadwa" }}>
          Galeri
        </NavLink>
      </Menu.Item> */}
      <Menu.Item key="5">
        <NavLink to={`${ARTICLE_LIST}`} style={{ fontFamily: "Kadwa" }}>
          Artikel
        </NavLink>
      </Menu.Item> 
      <Menu.Item key="6">
        <NavLink to={`${CONTACT_COMPANY}`} style={{ fontFamily: "Kadwa" }}>
          Kontak
        </NavLink>
      </Menu.Item>
    </Menu>
  );
};

export default MainMenu;
