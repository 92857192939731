import axios from "axios";

export const BASE_URL = "https://adm.koperasigks.co.id/api/v1/";

// export const BASE_URL =
//   process.env.NODE_ENV === "development"
//     ? "http://localhost:3001/"
//     : "https://adm.koperasigks.co.id/api/v1/";

export const base = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
  withCredentials: true,
});

base.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

base.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const config = err.config;
    if (config.url !== "/auth/login" && err.message && err.response) {
      /*
       * jika access token invalid, request access token menggunakan refresh token yang ada di cookie
       * */

      if (
        err.response.status === 401 &&
        config.url !== "/auth/refresh-token" &&
        !config._retry
      ) {
        config._retry = true;
        try {
          const res = await base.get("/auth/refresh-token");
          if (res.data.flag != 200) {
            return window.open("/login");
          }
          console.log(res);
          localStorage.setItem("token", res.data.result.access_token);
          return base(config);
        } catch (err) {
          return Promise.reject(err);
        }
      }

      /*
       * Jika request access token gagal atau refresh token invalid hapus access token di local storage lalu rediecct ke login
       */
      if (
        (err.response.status === 401 && config.url === "/auth/refresh-token") ||
        err.response.status === 403
      ) {
        localStorage.removeItem("token");
        window.location.reload();
      }
    }
    return Promise.reject(err);
  }
);
