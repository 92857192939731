import React from "react";
import { Fragment } from "react";
import styled from "styled-components";
import { Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

const ContainerFoooter = styled.div`
  background: #f4f9f8;
  color: #808080;
  border: 2px solid white;
  width: 100%;
  display: flex;
  align-self: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 4rem;
  padding-right: 1rem;
  font-family: Judson;
`;

const FooterGuide = styled.p`
  color: #2e2e2e;
  font-family: Judson;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
`;
const FooterGuideTouch = styled.p`
  color: #2e2e2e;
  font-family: Judson;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
`;

const FooterGuideInter = styled.p`
  color: #2e2e2e;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
`;
const DivDummy = styled.div`
  width: 48px;
  height: 48px;
  background: #ebebeb;
`;

const hudsonFont = styled.span`
  fontfamily: "Judson";
`;

const Footer = () => {
  return (
    <Fragment>
      <ContainerFoooter>
        <Row>
          <Col xs={6} md={3}>
            <Row>
              <Image src="/images/kpr/footerlogo.png" style={{ width: 200 }} />
            </Row>
            <hudsonFont style={{ fontSize: 18 }}>
              Koperasi Simpan Pinjam 2021
            </hudsonFont>
          </Col>
          <Col xs={6} md={3}>
            <FooterGuideTouch>Get in Touch</FooterGuideTouch>
            <Row>
              <Col md={2}>
                <Image src="/images/kpr/location-footer.png" />
              </Col>
              <Col md={7}>
                <hudsonFont>
                  Roseville Soho & Suite Unit F-3A, Jl. Kapten Sorbianto
                  Djojohadikusumo, BSD City, Tangerang, Banten
                </hudsonFont>
                1-8
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={2}>
                <Image src="/images/kpr/mail-footer.png" />
              </Col>
              <Col md={7}>ksp.globalkapitalsejahtera@gmail.com</Col>
            </Row>
            <br />
            <Row>
              <Col md={2}>
                <Image src="/images/kpr/phone-footer.png" />
              </Col>
              {/* <Col md={7}>+62818948839938</Col> */}
            </Row>
          </Col>
          <Col xs={6} md={3}>
            <FooterGuide>Kategori</FooterGuide>
            <Link
              to="/privacy-policy"
              style={{ textDecoration: "none", color: "grey" }}
            >
              <hudsonFont>Syarat & Ketentuan</hudsonFont>
            </Link>
            <br />
            <br />
            <Link
              to="/privacy-policy"
              style={{ textDecoration: "none", color: "grey" }}
            >
              <hudsonFont>Kebijakan Privasi</hudsonFont>
            </Link>
            <br />
            <br />
            <Link
              to="/roseville-soho-suite"
              style={{ textDecoration: "none", color: "grey" }}
            >
              <hudsonFont>Roseville Soho & Suite</hudsonFont>
            </Link>
            <br />
            <br />
            {/* <hudsonFont>Peta Situs</hudsonFont> */}
          </Col>
          <Col xs={6} md={3}>
            <FooterGuide>Berita</FooterGuide>
            <Row>
              {/* <Col md={2}>
                <DivDummy />
              </Col> */}
              <Col md={10}>
                <Link to={"/artikel-detail/1"} style={{ color: "#2e2e2e" }}>
                  <span style={{ fontSize: 16 }}>
                    15 Pertimbangan Beli Apartemen yang Perlu Dipahami
                  </span>
                </Link>
              </Col>
            </Row>
            <br />
            <Row>
              {/* <Col md={2}>
                <DivDummy />
              </Col> */}
              <Col md={10}>
                <Link to={"/artikel-detail/2"} style={{ color: "#2e2e2e" }}>
                  <span style={{ fontSize: 16 }}>
                    5 Cara Mendekorasi Ruang Kecil agar Tampak Besar
                  </span>
                </Link>
              </Col>
            </Row>
            <br />
            <Row>
              {/* <Col md={2}>
                <DivDummy />
              </Col> */}
              <Col md={10}>
                <Link to={"/artikel-detail/3"} style={{ color: "#2e2e2e" }}>
                  <span style={{ fontSize: 16 }}>
                    Inilah 7 Penyebab Mengapa Harga Apartemen akan Selalu Naik
                  </span>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </ContainerFoooter>
    </Fragment>
  );
};

export default Footer;
