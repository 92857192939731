import { base } from "../baseAuth";
import jwt_decode from "jwt-decode";

export const getDetailUser = async () => {
  try {
    let token = localStorage.getItem("token");
    if (!token) {
      return null;
    }

    let decode = jwt_decode(token);
    let id = decode.id;
    const response = await base.get(`/customer/${id}`);
    return Promise.resolve(response.data);
  } catch (error) {
    console.log(error, "test");
    return Promise.reject(error);
  }
};
