import React, { useState, useContext } from "react";
import Sticky from "react-stickynode";
import { Link, withRouter } from "react-router-dom";
import { IoIosClose } from "react-icons/io";
import { Button, Drawer, Menu, Row } from "antd";
import Logo from "components/UI/Logo/Logo";
import Navbar from "components/Navbar/Navbar";
import { AuthContext } from "context/AuthProvider";
import { LayoutContext } from "context/LayoutProvider";
import useWindowSize from "library/hooks/useWindowSize";
import { PROFILE_PAGE } from "settings/constant";
import AuthMenu from "./AuthMenu";
import MainMenu from "./MainMenu";
import MobileMenu from "./MobileMenu";
import HeaderWrapper, {
  MobileNavbar,
  CloseDrawer,
  ButtonLoginMenu,
} from "./Header.style";

const avatarImg = `http://s3.amazonaws.com/redqteam.com/isomorphic-reloaded-image/profilepic.png`;
const LogoIcon = () => (
  <Link to="/">
    <img src="/images/gkslogo.png" style={{ width: "80%" }} />
  </Link>
);
const LogoIconActive = () => (
  <Link to="/">
    <img src="/images/gkslogo.png" />
  </Link>
);

const menu = <Menu onClick={() => console.log("test")}></Menu>;

export default withRouter(function Header({ location }) {
  const [{ searchVisibility }] = useContext(LayoutContext);
  const { loggedIn } = useContext(AuthContext);
  const { width } = useWindowSize();
  const [state, setState] = useState(false);

  const sidebarHandler = () => {
    setState(!state);
  };

  const headerType = "default";

  return (
    <HeaderWrapper>
      <Sticky
        top={headerType === "transparent" ? -1 : 0}
        innerZ={1039}
        activeClass="isHeaderSticky"
      >
        {width > 991 ? (
          <Navbar
            logo={
              <>
                {headerType === "transparent" ? (
                  <Link to="/">
                    <LogoIcon />
                  </Link>
                ) : (
                  <Link to="/">
                    <LogoIconActive />
                  </Link>
                )}
              </>
            }
            navMenu={<MainMenu />}
            authMenu={<AuthMenu />}
            isLogin={loggedIn}
            avatar={<Logo src={avatarImg} />}
            profileMenu={
              <Row>
                <Link to={PROFILE_PAGE}>
                  <ButtonLoginMenu primary>Akun</ButtonLoginMenu>
                </Link>
              </Row>
              // <Radio.Group>
              //   <Radio.Button
              //     value="Profile"
              //     onClick={() => console.log("large")}
              //   >
              //     Akun
              //   </Radio.Button>
              //   <Radio.Button
              //     value="default"
              //     onClick={() => console.log("deff")}
              //   >
              //     logout
              //   </Radio.Button>
              // </Radio.Group>

              // <>
              //   <Row>
              //
              //       <Button type="primary" onClick={() => getDetailUser()}>
              //         Akun
              //       </Button>
              //       {/* <Button
              //         type="primary"
              //         icon={<PoweroffOutlined />}
              //         // loading={loadings[2]}
              //         onClick={() => this.enterLoading(2)}
              //       /> */}
              //
              //   </Row>
              // </>
            }
            headerType={headerType}
            location={location}
            searchVisibility={searchVisibility}
          />
        ) : (
          <MobileNavbar className={headerType}>
            <Link to="/">
              <img src="/images/gkslogo.png" alt="header" />
            </Link>
            <Button
              className={`hamburg-btn ${state ? "active" : ""}`}
              onClick={sidebarHandler}
            >
              <span />
              <span />
              <span />
            </Button>
            <Drawer
              placement="right"
              closable={false}
              onClose={sidebarHandler}
              width="285px"
              className="mobile-header"
              visible={state}
            >
              <CloseDrawer>
                <button onClick={sidebarHandler}>
                  <IoIosClose />
                </button>
              </CloseDrawer>
              {loggedIn ? (
                <Link to={PROFILE_PAGE}>
                  <ButtonLoginMenu primary>Akun</ButtonLoginMenu>
                </Link>
              ) : (
                <AuthMenu className="auth-menu" />
              )}
              <MobileMenu className="main-menu" onClick={sidebarHandler} />
            </Drawer>
          </MobileNavbar>
        )}
      </Sticky>
    </HeaderWrapper>
  );
});
