// General Page Section
export const HOME_PAGE = "/";
export const AGENTS_PAGE = "/agents";

// Listing Single Page Section
export const LISTING_POSTS_PAGE = "/produk";
export const SINGLE_POST_PAGE = "/post";
export const SIMULASI_KPR = "/simulasi-kpr";
export const APARTEMEN_PAGES = "/roseville-soho-suite";

// Agent Profile Page Section
export const ABOUT_COMPANY_PAGE = "/tentang";
export const AGENT_ACCOUNT_SETTINGS_PAGE = "/account-settings";
export const AGENT_PROFILE_EDIT_PAGE = "/edit";
export const AGENT_IMAGE_EDIT_PAGE = "/change-image";
export const AGENT_PASSWORD_CHANGE_PAGE = "/change-password";
export const AGENT_PROFILE_DELETE = "/delete";
export const AGENT_PROFILE_FAVOURITE = "/favourite-post";
export const AGENT_PROFILE_CONTACT = "/contact";
export const ORDER_ACTIVE = "/order-active";
export const ORDER_HISTORY = "/order-history";
export const ORDER_PAYMENT = "/order-payment";
export const ARTICLE_DETAIL = "/artikel-detail/1";
export const ARTICLE_DETAIL2 = "/artikel-detail/2";
export const ARTICLE_DETAIL3 = "/artikel-detail/3";
export const ARTICLE_DETAIL4 = "/artikel-detail/4";
export const ARTICLE_DETAIL5 = "/artikel-detail/5";
export const ARTICLE_DETAIL6 = "/artikel-detail/6";
export const ARTICLE_LIST = "/artikel";

// Other Pages
export const CONTACT_COMPANY = "/contact";
export const GALERY_PAGE = "/galery";
export const TERMS_PAGE = "/terms-conditions";
export const PRIVACY_PAGE = "/privacy-policy";
export const ADD_HOTEL_PAGE = "/add-hotel";
export const ORDER_DETAIL = "/order-detail";
export const GALERY_DETAIL = "/galery-detail";

// Login / Registration Page
export const LOGIN_PAGE = "/sign-in";
export const PROFILE_PAGE = "/profile";
export const REGISTRATION_PAGE = "/sign-up";
export const CHANGE_PASSWORD_PAGE = "/change-password";
export const FORGET_PASSWORD_PAGE = "/forget-password";
export const VERIFY_CODE = "/verify-otp";

// Category
export const LISTING_CATEGORY = "/category";
