import React from "react";
import { NavLink } from "react-router-dom";
import { Menu } from "antd";
import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  ABOUT_COMPANY_PAGE,
  CONTACT_COMPANY,
  GALERY_PAGE,
  SIMULASI_KPR,
} from "settings/constant";

const MobileMenu = ({ className, onClick }) => {
  // auth context

  return (
    <Menu className={className}>
      <Menu.Item key="0" onClick={onClick}>
        <NavLink exact to={`${HOME_PAGE}`} style={{ fontFamily: "Kadwa" }}>
          Home
        </NavLink>
      </Menu.Item>
      <Menu.Item key="1" onClick={onClick}>
        <NavLink to={`${ABOUT_COMPANY_PAGE}`} style={{ fontFamily: "Kadwa" }}>
          Tentang
        </NavLink>
      </Menu.Item>
      <Menu.Item key="3" onClick={onClick}>
        <NavLink to={`${LISTING_POSTS_PAGE}`} style={{ fontFamily: "Kadwa" }}>
          Produk
        </NavLink>
      </Menu.Item>
      <Menu.Item key="4" onClick={onClick}>
        <NavLink to={`${SIMULASI_KPR}`} style={{ fontFamily: "Kadwa" }}>
          Simulasi
        </NavLink>
      </Menu.Item>
      {/* <Menu.Item key="5" onClick={onClick}>
        <NavLink to={`${GALERY_PAGE}`} style={{ fontFamily: "Kadwa" }}>
          Galeri
        </NavLink>
      </Menu.Item> */}
      <Menu.Item key="6" onClick={onClick}>
        <NavLink to={`${CONTACT_COMPANY}`} style={{ fontFamily: "Kadwa" }}>
          Kontak
        </NavLink>
      </Menu.Item>
    </Menu>
  );
};

export default MobileMenu;
